










import Vue from 'vue';
import { Component, Inject, Prop, Provide } from 'vue-property-decorator';
import Category from './Category.vue';

@Component
export default class OverviewItem extends Vue {
    @Provide('registerCategory') childRegistration = this.registerCategory;
    @Inject({
        default: () => null
    }) readonly registerOverviewItem!: Function;

    categories: string[] = [];
    showFilter = true;
    show = true;
    private categoriesRegistered = false;

    mounted() {
        this.registerOverviewItem(this);
    }

    registerCategory(category: Category) {
        this.categories.push(category.categoryId);

        // unmount category components after they have been registered
        if (this.$children.filter(this.filterCategories).length === this.categories.length) {
            this.categoriesRegistered = true;
        }
    }

    // check children to only count slide components
    filterCategories(child: Vue) {
        return child.$vnode && child.$vnode.componentOptions && child.$vnode.componentOptions.tag === 'slct-category';
    }
}
