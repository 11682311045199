














































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import axios from 'axios';

const FORM_ENDPOINT = '/.rest/api/v2/form/service';

@Component
export default class ServiceForm extends Vue {
    @Prop({ required: true }) backLabel: string;
    @Prop({ required: true }) submitLabel: string;
    @Prop({ required: true }) serverError: string;

    success = false;
    checked = false;
    loading = false;
    errorMessage = '';

    async submit({ target }) {
        this.errorMessage = '';
        this.loading = true;
        const form: HTMLFormElement = target.form;
        const fd: FormData = new FormData(form);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const encodedFd: URLSearchParams = new URLSearchParams(fd as any); // this works but type checking fails without `any`

        try {
            if (form.checkValidity()) {
                const res = await axios({
                    method: 'post',
                    url: `${this.$contextPath}${FORM_ENDPOINT}/${this.$site}/${this.$lang}`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: encodedFd
                });
                if (res.data.success) {
                    this.success = true;
                } else {
                    this.errorMessage = this.serverError;
                }
                this.loading = false;
            } else {
                for (let i = 0; i < form.elements.length; i++) {
                    // trigger html validation messages
                    if (!(form.elements[i] as HTMLFormElement).reportValidity()) {
                        this.loading = false;
                        break;
                    }
                }
            }
        } catch (err) {
            this.errorMessage = this.serverError;
            this.loading = false;
        }

        // scroll to top of section
        let section: HTMLElement = form.offsetParent as HTMLElement;
        while (section.tagName.toLowerCase() !== 'section') {
            section = section.offsetParent as HTMLElement;
        }
        window.scrollTo({ top: section.offsetTop, behavior: 'smooth' });
    }

    toggle() {
        this.checked = !this.checked;
    }
}
