

































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';
import axios from 'axios';
import Helper from '../../libs/Helper';

const FORM_ENDPOINT = '/.rest/api/v2/form';
const PR_FORM_ENDPOINT = '/.rest/api/v2/form/pr';
const TRACKING_PARAMS = ['gclid', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term'];

@Component({
    components: { Icon }
})
export default class MultiStepForm extends Vue {
    @Prop() firstStepOptions!: { [key: string]: string };
    @Prop() secondStepOptions!: { [key: string]: string };
    @Prop() otherOptions: { [key: string]: string };
    @Prop({ required: true }) backLabel: string;
    @Prop({ required: true }) submitLabel: string;
    @Prop({ required: true }) serverError: string;
    @Prop({ default: false, type: Boolean }) pelican: boolean;
    @Prop({ default: false, type: Boolean }) showSustainabilityCheckbox: boolean;

    selectedOptions = [];
    success = false;
    checked = false;
    loading = false;
    errorMessage = '';
    firstStep = 1;
    filesizes: number[] = [];
    filenames: string[] = [];

    select(option: string) {
        this.selectedOptions.push(option);
    }

    back(steps = 1) {
        if (steps > this.selectedOptions.length) {
            return;
        }
        for (let i = 0; i < steps; i++) {
            this.selectedOptions.pop();
        }
    }

    async submit({ target }) {
        this.errorMessage = '';
        this.loading = true;
        const form: HTMLFormElement = target.form;
        const fd: FormData = new FormData(form);

        // check the total filesize and append the filenames to the form
        if (this.fileSizeError) {
            (form.elements.namedItem('file1') as HTMLInputElement).setCustomValidity('Upload limit is 5MB');
            return;
        }
        fd.set('filename', this.filenames.join(','));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const encodedFd: URLSearchParams = new URLSearchParams(fd as any); // this works but type checking fails without `any`

        try {
            if (form.checkValidity()) {
                const res = await axios({
                    method: 'post',
                    url: `${this.$contextPath}${this.pelican ? PR_FORM_ENDPOINT : FORM_ENDPOINT}/${this.$site}/${this.$lang}`,
                    headers: {
                        'Content-Type': this.pelican ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
                    },
                    data: this.pelican ? fd : encodedFd
                });
                // send form submit event to gtm
                if (window.dataLayer) {
                    const dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        event: 'formSubmit',
                        purpose: fd.get('purpose'),
                        productinterest: fd.get('productinterest'),
                        customertype: fd.get('customertype')
                    });
                }
                if (res.data.success) {
                    this.success = true;
                } else {
                    this.errorMessage = this.serverError;
                }
                this.loading = false;
            } else {
                for (let i = 0; i < form.elements.length; i++) {
                    // trigger html validation messages
                    if (!(form.elements[i] as HTMLFormElement).reportValidity()) {
                        this.loading = false;
                        break;
                    }
                }
            }
        } catch (err) {
            this.errorMessage = this.serverError;
            this.loading = false;
        }

        // scroll to top of section
        let section: HTMLElement = form.offsetParent as HTMLElement;
        while (section.tagName.toLowerCase() !== 'section') {
            section = section.offsetParent as HTMLElement;
        }
        window.scrollTo({ top: section.offsetTop, behavior: 'smooth' });
    }

    toggle() {
        this.checked = !this.checked;
    }

    removeFile(name: string) {
        ((this.$el as HTMLFormElement).elements.namedItem(name) as HTMLInputElement).value = '';
        const index = this.filenames.findIndex(x => x === name);
        this.filenames.splice(index, 1);
        this.filesizes.splice(index, 1);
    }

    selectFile({ target }: { target: HTMLInputElement}, index: number) {
        const files = target.files;
        if (files.length) {
            const file = files.item(0);
            if (this.filenames[index]) {
                this.filenames[index] = file.name;
            } else {
                this.filenames.push(file.name);
            }
            if (this.filesizes[index]) {
                // use Vue.set to trigger reactivity, otherwise fileSizeError will not be updated
                Vue.set(this.filesizes, index, file.size);
            } else {
                this.filesizes.push(file.size);
            }
        } else {
            this.filenames.splice(index, 1);
            this.filesizes.splice(index, 1);
        }
    }

    getTrackingParams(params: URLSearchParams) {
        return TRACKING_PARAMS.reduce((obj, x) => {
            if (params.has(x)) {
                obj[x] = params.get(x);
            }
            return obj;
        }, {});
    }

    get currentOptions(): { [key: string]: string } {
        if (this.currentStep === 1) {
            return this.firstStepOptions;
        } else if (!this.pelican && this.selectedOptions[0] === this.fsok[2]) {
            return this.otherOptions;
        } else if (this.pelican && this.selectedOptions[0] === this.fsok[2]) {
            return this.otherOptions;
        } else {
            return this.secondStepOptions;
        }
    }

    get currentStep(): number {
        return this.selectedOptions.length + 1;
    }

    get firstStepValue(): string {
        return this.selectedOptions.length ? `${this.selectedOptions[0]}` : '';
    }

    get secondStepValue(): string {
        return this.selectedOptions.length > 1 ? `${this.selectedOptions[1]}` : '';
    }

    get hasInquiry(): boolean {
        if (this.pelican && this.currentStep === 3 && this.selectedOptions.length === 2) {
            return (this.selectedOptions[0] === this.fsok[0] && this.selectedOptions[1] === this.ssok[1]) ||
                (this.selectedOptions[0] === this.fsok[1] && this.selectedOptions[1] === this.ssok[1]);
        }
        return false;
    }

    get fsok(): string[] {
        return Object.keys(this.firstStepOptions);
    }

    get ssok(): string[] {
        return Object.keys(this.secondStepOptions);
    }

    get hasMarketing(): boolean {
        if (this.selectedOptions.length === 2) {
            return this.selectedOptions[0] === this.fsok[0];
        }
        return false;
    }

    get currentPage(): string {
        return location.pathname + location.search;
    }

    get hasComplaint(): boolean {
        return this.selectedOptions.includes('complaint');
    }

    get trackingParams(): { [key: string]: string } {
        const cookie = Helper.getCookie('lzcp');
        if (cookie) {
            const params = new URLSearchParams(cookie.replace(/^.*\?/, ''));
            const cookieParams = this.getTrackingParams(params);
            const urlParams = this.getTrackingParams(new URLSearchParams(location.search));
            return { ...cookieParams, ...urlParams };
        }
        return {};
    }

    get fileSizeError(): boolean {
        return this.filesizes.reduce((a, b) => a + b, 0) > (5 * 1024 * 1024);
    }
}
