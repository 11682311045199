













import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';

@Component({
    components: { Icon }
})
export default class FooterNavItem extends Vue {
    @Prop({ required: true }) link: string;

    open = false;

    onClick(event: MouseEvent) {
        if (!this.open) {
            event.stopPropagation();
            this.open = true;
        }
    }

    @Watch('open')
    openChanged() {
        document.body.classList.toggle('overflow-hidden', this.open);
    }
}
