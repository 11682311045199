






























































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';
import axios from 'axios';
import LoadingSpinner from '../base/LoadingSpinner.vue';
import { addToState, removeFromState } from '../../utils';

export interface Category {
    id: string;
    label: string;
}

export interface SearchLabels {
    filter: string;
    headline: string;
    results: string;
    more: string;
}

export interface Result {
    imageUrls: Record<string, string>;
    title: string;
    text: string;
    link: string;
    categories: string[];
}

export interface ResultResponse {
    totalResults: number;
    teasers: Result[];
}

@Component({
    components: {
        LoadingSpinner,
        Icon
    }
})
export default class SearchResults extends Vue {
    @Prop({ default: () => [] }) roots: string[];
    @Prop({ default: {} }) defaultCategories: Category[];
    @Prop({ default: {} }) filterCategories: Category[];
    @Prop({ default: {} }) labels: SearchLabels;
    @Prop({ default: 6 }) batchSize: number;
    @Prop({ required: true }) fallbackImage: string;

    apiBaseUrl = '/.rest/api/v2/teasers';

    selected: string[] = [];
    filterOpen = false;
    loading = false;

    totalItems = 0;
    offset = 0;
    items: Result[] = [];

    mounted(): void {
        if (!this.readHash()) {
            this.getResults(true);
        }
    }

    get apiUrl(): string {
        return `${this.$contextPath}${this.apiBaseUrl}?site=${this.$site}&locale=${this.$lang}&offset=${this.offset}` +
               `&limit=${this.batchSize}&${this.rootsString}&${this.selectedCategoriesString}`;
    }

    get rootsString(): string {
        return this.roots.map(s => 'root=' + s).join('&');
    }

    get selectedCategoriesString(): string {
        if (this.selected.length > 0) {
            return this.selected.map(s => 'category=' + s).join('&');
        }
        return this.defaultCategories.map(c => c.id).map(s => 'category=' + s).join('&');
    }

    get hasMore(): boolean {
        return this.totalItems > this.items.length;
    }

    get more(): number {
        return Math.min(this.batchSize, this.totalItems - this.items.length);
    }

    loadFromUrl(): void {
        const params = new URLSearchParams(window.location.search);
        if (params.has('categories')) {
            this.selected = params.get('categories').split(',');
        } else {
            this.getResults(true);
        }
    }

    isSelected(id: string): boolean {
        return this.selected.includes(id);
    }

    loadMore(): void {
        this.getResults(false);
    }

    readHash(): boolean {
        const hash = location.hash.replace(/^#/, '');
        const filter = hash.split('&').find(x => x.includes('categories:'));
        if (filter) {
            const split = filter.split(':');
            if (split.length === 2 && split[1].length > 0) {
                this.selected = split[1].split(',');
                return true;
            }
        }
        return false;
    }

    updateHash() {
        removeFromState('categories');
        this.selected.forEach(cat => {
            addToState('categories', cat);
        });
    }

    getResults(clear: boolean): void {
        this.loading = true;
        if (clear) {
            this.offset = 0;
            this.items = [];
        }
        axios.get(this.apiUrl)
            .then(res => {
                const result: ResultResponse = res.data;
                this.totalItems = result.totalResults;
                this.items.push(...result.teasers);
                this.offset = this.items.length;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                this.loading = false;
            });
    }

    @Watch('selected')
    watchSelected(): void {
        this.updateHash();
        this.getResults(true);
    }
}
