
import Vue, { CreateElement, VNode } from 'vue';
import { Component, Inject, Prop, Provide } from 'vue-property-decorator';
import { NavItem } from './MainNavItem.vue';
import MainNavigation from './MainNavigation.vue';
import Icon from '../base/Icon.vue';

@Component({
    components: { Icon }
})
export default class MetaNavItem extends Vue implements NavItem {
    @Provide('registerMetaNavSubItem') childRegistration = this.register;
    @Inject({
        default: () => null
    }) readonly registerMetaNavItem!: Function;

    @Inject() readonly mainNavTag!: string;

    @Inject({
        default: () => null
    }) readonly registerSiteNavItem!: Function;

    @Prop({ required: true }) link: string;
    @Prop({ required: true }) label: string;
    @Prop({ required: true }) type: 'meta' | 'site';
    @Prop({ default: '_self' }) target: string;
    @Prop({ default: '' }) icon: string;
    @Prop({ required: true }) level: number;
    @Prop({ default: false }) pelican: boolean;

    items: MetaNavItem[] = [];

    mounted() {
        // if the parent is also a nav-item register it there, otherwise use injected register method (form main navigation)
        if (this.$parent.$vnode && this.$parent.$vnode.tag === this.$vnode.tag) {
            (this.$parent as MetaNavItem).register(this);
        } else if (this.$parent.$vnode.tag === this.mainNavTag || (this.$parent.$parent.$vnode && this.$parent.$parent.$vnode.tag === this.mainNavTag)) {
            if (this.type === 'meta') {
                this.registerMetaNavItem(this);
            }
        } else if (this.type === 'site') {
            this.registerSiteNavItem(this);
        } else {
            this.findParentItem();
        }
    }

    // traverse parents and check if we're inside another MetaNavItem
    findParentItem() {
        let parent: Vue = this.$parent;
        while (parent.$parent) {
            parent = parent.$parent;
            if (parent.$vnode && parent.$vnode.tag === this.$vnode.tag) {
                (parent as MetaNavItem).register(this);
                break;
            }
        }
    }

    /**
     * we need a render function because we either render a link or a dropdown, without a wrapper element
     */
    render(h: CreateElement) {
        if (this.$slots.default && this.$slots.default.length) {
            return h('dropdown', {
                staticClass: 'px-2 py-3 !!text-gray',
                props: {
                    title: this.label,
                    alignment: this.type === 'meta' ? 'left' : 'right'
                }
            }, [
                this.icon.length ? h('icon', {
                    props: { name: this.icon },
                    slot: 'title',
                    staticClass: `${this.pelican ? 'w-5 h-5' : 'w-4 h-4 rounded-full'} overflow-hidden flex-grow-0 mr-2.5`
                }) : null,
                h('span', { slot: 'title' }, this.label),
                ...this.$slots.default.filter(x => x.tag).map((child: VNode) => h('dropdown-item', [child]))
            ]);
        }
        return h('a', {
            staticClass: 'flex items-center px-2 py-3 text-black opacity-60 lg:text-gray lg:opacity-100 transition-colors hover:opacity-100 hover:text-red',
            attrs: { href: this.link, target: this.target }
        }, this.icon.length ? [
            h('icon', {
                props: { name: this.icon },
                staticClass: `${this.pelican ? 'w-5 h-5' : 'w-4 h-4 rounded-full'} overflow-hidden flex-grow-0 mr-2.5`
            }),
            this.label
        ] : this.label);
    }

    private register(item) {
        this.items.push(item);
    }
}
