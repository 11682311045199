
































































import Vue from 'vue';
import { Component, Prop, Provide } from 'vue-property-decorator';
import ProductImage from './ProductImage.vue';
import Icon from './base/Icon.vue';

@Component({
    components: { Icon }
})
export default class ProductImages extends Vue {
    @Provide('registerProductImage') childRegistration = this.registerImage;

    @Prop({ default: false, type: Boolean }) author: boolean;

    images: ProductImage[] = [];

    registerImage(image: ProductImage) {
        this.images.push(image);
        if (this.images.length === 1) {
            this.images[0].active = true;
        }
    }

    onThumbClicked(image: ProductImage) {
        this.images.forEach(img => {
            img.active = false;
        });
        image.active = true;
    }
}
