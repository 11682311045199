

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class CheckboxGroup extends Vue {
    @Prop({ required: true }) name: string;
    @Prop({ default: false, type: Boolean }) required: boolean;
    @Prop({ default: false, type: Boolean }) splitFields: boolean;

    values: string[] = [];
    checkboxes: HTMLInputElement[] = [];

    mounted() {
        const elements = this.$el.querySelectorAll<HTMLInputElement>('input[type="checkbox"]');
        for (let i = 0; i < elements.length; i++) {
            this.checkboxes.push(elements[i]);
        }

        this.checkboxes.forEach((checkbox: HTMLInputElement) => {
            checkbox.name = '';
            checkbox.oninput = ({ target }) => this.toggle((target as HTMLInputElement).checked, (target as HTMLInputElement).value);
        });
    }

    toggle(on: boolean, value: string) {
        if (this.values.indexOf(value) < 0 && on) {
            this.values.push(value);
        } else if (this.values.indexOf(value) >= 0 && !on) {
            this.values.splice(this.values.indexOf(value), 1);
        }
        this.$emit('update', this.values);
    }

    get value() {
        return this.values.length ? this.values.sort().join(',') : null;
    }
}
