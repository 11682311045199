








import Vue from 'vue';
import { Component, Prop, Inject } from 'vue-property-decorator';

@Component
export default class DropdownItem extends Vue {
    @Prop() label: string;
    @Prop({ default: '' }) value: string;
    @Prop({ default: false, type: Boolean }) active: boolean;
    @Prop({ default: false, type: Boolean }) disabled: boolean;
    @Prop({ default: false, type: Boolean }) cancelable: boolean;
    @Inject({
        default: () => null
    }) readonly register!: Function;

    contrast = false;

    mounted() {
        // register self on parent component
        this.register(this);
    }

    onClick() {
        if (this.disabled) {
            return;
        }
        this.$emit('click', { label: this.label, value: this.value });
    }
}
