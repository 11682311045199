











import Vue from 'vue';
import { Component, Inject, Prop, Ref } from 'vue-property-decorator';

@Component
export default class Tab extends Vue {
    @Ref('content') content: HTMLElement;
    @Inject({
        default: () => null
    }) readonly registerTab!: Function;

    @Prop({ required: true }) title: string;

    active = true;

    mounted() {
        this.registerTab(this, this.content.clientHeight);
    }
}
