



















import Vue from 'vue';
import { Component, Prop, Provide } from 'vue-property-decorator';
import Tab from './Tab.vue';

@Component
export default class Tabs extends Vue {
    @Provide('registerTab') childRegistration = this.register;

    @Prop({ default: true }) centered: boolean;
    @Prop({ default: false }) editMode: boolean;

    tabs: Tab[] = [];
    height = 0;

    mounted() {
        window.addEventListener('resize', this.onResize);
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    }

    register(tab: Tab, height: number) {
        this.tabs.push(tab);
        // set height to the highest tab to prevent jumping
        if (height > this.height) {
            this.height = height;
        }

        if (this.$children.filter(this.filterItems).length === this.tabs.length) {
            this.tabs.forEach((tab, i) => {
                tab.active = i === 0;
            });
        }
    }

    // check children to only count tab components
    filterItems(child: Vue) {
        return child.$vnode && child.$vnode.componentOptions && child.$vnode.componentOptions.tag === 'slct-tab';
    }

    activate(tab: Tab) {
        if (tab.active) {
            return;
        }
        this.tabs.forEach(t => {
            t.active = false;
        });
        tab.active = true;
    }

    // update tabs height on window resize
    onResize() {
        this.height = this.tabs.reduce((max, tab: Tab) => Math.max(max, tab.content.clientHeight), 0);
    }

    get maxHeight() {
        return {
            '--custom-height': (this.editMode || this.tabs.length < 2) ? 'auto' : `${this.height}px`
        };
    }
}
