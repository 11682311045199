














import { PropType } from 'vue';

interface RadioOption {
    value: string;
    label: string;
}
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        options: {
            type: Array as PropType<RadioOption[]>,
            required: true
        },
        fieldsetClasses: {
            type: String,
            required: false
        },
        labelClasses: {
            type: String,
            required: false
        },
        labelWrapperClasses: {
            type: String,
            required: false
        },
        slotWrapperClasses: {
            type: String,
            required: false
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            selectedOption: null
        };
    }
};

