











export default {
    props: {
        errorMessage: {
            type: String,
            default: ''
        },
        productWrapperClasses: {
            type: String,
            default: ''
        },
        errorClasses: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            products: [],
            valid: false,
            displayError: false
        };
    },
    provide() {
        return {
            registerProduct: this.registerProduct
        };
    },
    inject: {
        register: {
            default: () => null
        }
    },
    methods: {
        registerProduct(product) {
            this.products.push(product);
        },
        checkCustomValidity(): boolean {
            this.products.forEach(product => product.checkCustomValidity());
            const someSelected = this.products.some(product => product.validityState === 1);
            const someFailed = this.products.some(product => product.validityState > 1);
            this.valid = someSelected && !someFailed; // at least one selected and none failed
            return this.valid;
        },
        reportCustomValidity() {
            this.displayError = false;
            if (!this.checkCustomValidity()) {
                this.displayError = true;
                this.products.forEach(product => product.reportCustomValidity());
                this.$refs.error.scrollIntoView({ block: 'end' });
            }
        }
    },
    mounted() {
        this.register(this);
    }
};
