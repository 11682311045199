























import Vue from 'vue';
import { Component, Inject, Prop, Provide } from 'vue-property-decorator';
import Category from './Category.vue';
import Icon from '../base/Icon.vue';

@Component({
    components: { Icon }
})
export default class FilterArticle extends Vue {
    @Provide('registerCategory') childRegistration = this.registerCategory;
    @Inject({
        default: () => null
    }) readonly registerFilter!: Function;

    @Prop({ required: true }) name: string;
    @Prop({ required: true }) filterKey: string;
    @Prop({ required: true }) categoryId: string;
    @Prop({ required: true }) index: number;

    filterActive = true;
    filterExits = true;
    categories: Category[] = [];
    selectedCategory = { id: '', key: '', name: '' };

    mounted() {
        this.registerFilter(this);
    }

    registerCategory(category: Category) {
        category.$on('toggle', this.onCategoryToggled);
        this.categories.push(category);
    }

    // clean all filters
    cleanFilter() {
        this.$emit('toggle-category', {
            id: this.selectedCategory.id,
            key: this.selectedCategory.key
        });
        this.$emit('filter-changed', { filter: this.categoryId, filterKey: this.filterKey, categories: [] });
        this.selectedCategory.name = '';
        this.selectedCategory.key = '';
        this.selectedCategory.id = '';
    }

    setCategories(categoryKeys: string[]): { id: string; key: string }[] {
        const categories = this.categories.filter(c => categoryKeys.includes(c.categoryKey)).map(({ categoryId, categoryKey, name }) => ({ id: categoryId, key: categoryKey, name }));
        if (categories[0]) {
            this.selectedCategory.name = categories[0].name;
            this.selectedCategory.key = categories[0].key;
            this.selectedCategory.id = categories[0].id;
        }
        return categories;
    }

    // forward event from category
    onCategoryToggled(payload) {
        if (this.selectedCategory.id === payload.id) {
            this.cleanFilter();
        } else {
            this.selectedCategory = payload;
            this.$emit('toggle-category', {
                id: this.selectedCategory.id,
                key: this.selectedCategory.key
            });
            this.$emit('filter-changed', {
                filter: this.categoryId,
                filterKey: this.filterKey,
                categories: {
                    id: this.selectedCategory.id,
                    key: this.selectedCategory.key
                }
            });
        }
    }
}
