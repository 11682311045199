





























import Icon from './base/Icon.vue';
import Teleport from 'vue2-teleport';
export default {
    components: {
        Teleport,
        Icon
    },
    data() {
        return {
            open: false
        };
    },
    methods: {
        openPopUp() {
            document.body.classList.toggle('overflow-hidden', true);
            this.open = true;
        },
        closePopUp() {
            document.body.classList.toggle('overflow-hidden', false);
            this.open = false;
        }
    }
};
