































import Vue from 'vue';
import { Component, Prop, Provide } from 'vue-property-decorator';
import axios from 'axios';
import LoadingSpinner from '../base/LoadingSpinner.vue';

const FORM_ENDPOINT = '/.rest/api/v1/form';
const FORM_ENDPOINT_HR = '/.rest/api/v2/form/hr';
const FORM_ENDPOINT_FRUECHTEBOX_ORDER = '/.rest/api/v2/order';

interface FruitBoxLabels {
    label: string;
    weights: FruitBoxLabel[];
}

interface FruitBoxLabel {
    label: string;
}
@Component({
    components: {
        LoadingSpinner
    }
})
export default class FormSubmit extends Vue {
    @Prop({ required: true }) serverError: string;
    @Prop({ required: true }) successMessage: string;
    @Prop({ required: true }) submitLabel: string;
    @Prop({ default: 'checkbox' }) checkboxClasses: string;
    @Prop({ type: Boolean, default: false }) subtle: boolean;
    @Prop({ type: Boolean, default: false }) isHrForm: boolean;
    @Prop({ type: Boolean, default: false }) isFruechteboxOrderForm: boolean;
    @Prop({ default: [] }) fruitboxLabels: FruitBoxLabels[];
    @Prop({ default: () => [] }) fruitboxSubscriptionLabels: FruitBoxLabel[];
    @Provide('register') childRegistration = this.registerItem;

    items = [];
    sending = false;
    success = false;
    checked = false;
    errorMessage = '';

    async submit({ target }) {
        this.errorMessage = '';
        const form: HTMLFormElement = target.form;
        const fd: FormData = new FormData(form);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const encodedFd: URLSearchParams = new URLSearchParams(fd as any); // this works but type checking fails without `any`

        try {
            if (form.checkValidity() && this.checkItemsValidity()) {
                this.sending = true;
                const endpoint = this.isFruechteboxOrderForm ? `${this.$contextPath}${FORM_ENDPOINT_FRUECHTEBOX_ORDER}/${this.$lang}` : (this.isHrForm ? `${this.$contextPath}${FORM_ENDPOINT_HR}/${this.$site}/${this.$lang}` : `${this.$contextPath}${FORM_ENDPOINT}/${this.$site}`);
                const res = await axios({
                    method: 'post',
                    url: endpoint,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: encodedFd
                });
                // send form submit event to gtm
                if (window.dataLayer) {
                    const dataLayer = window.dataLayer || [];
                    if (this.isFruechteboxOrderForm) {
                        const boxType = [];
                        const boxWeight = [];
                        const boxPrice = [];
                        const boxCount = [];
                        const boxFrequency = [];
                        if ((fd.get('twoPackAmount') ?? 0) > 0) {
                            boxType.push(this.fruitboxLabels[0].label);
                            boxCount.push(fd.get('twoPackAmount'));
                            const weightOption = fd.get('twoPackWeightOption');
                            if ((weightOption ?? 0) > 0 && typeof weightOption === 'string') {
                                const weightOptionNumber: number = parseInt(weightOption) - 1;
                                const weightOptionLabel = this.fruitboxLabels[0].weights[weightOptionNumber].label;
                                if (weightOptionLabel.includes('|')) {
                                    const weightLabelSplit = weightOptionLabel.split('|');
                                    boxWeight.push(weightLabelSplit[0].trim().split(' ')[0]);
                                    if (weightLabelSplit.length > 1) {
                                        boxPrice.push(weightLabelSplit[1].trim().split(' ')[0]);
                                    }
                                } else {
                                    boxPrice.push(this.fruitboxLabels[0].weights[weightOptionNumber].label);
                                    boxWeight.push(this.fruitboxLabels[0].weights[weightOptionNumber].label);
                                }
                            } else {
                                boxPrice.push('-1');
                                boxWeight.push('-1');
                            }
                            const subscriptionOption = fd.get('twoPackSubscriptionFrequencyOption');
                            if ((subscriptionOption ?? 0) > 0 && typeof subscriptionOption === 'string') {
                                const subscriptionOptionNumber: number = parseInt(subscriptionOption) - 1;
                                const subscriptionOptionLabel = this.fruitboxSubscriptionLabels[subscriptionOptionNumber].label;
                                boxFrequency.push(subscriptionOptionLabel);
                            } else {
                                boxFrequency.push('-1');
                            }
                        }
                        if ((fd.get('fivePackAmount') ?? 0) > 0) {
                            boxType.push(this.fruitboxLabels[1].label);
                            boxCount.push(fd.get('fivePackAmount'));
                            const weightOption = fd.get('fivePackWeightOption');
                            if ((weightOption ?? 0) > 0 && typeof weightOption === 'string') {
                                const weightOptionNumber: number = parseInt(weightOption) - 1;
                                const weightOptionLabel = this.fruitboxLabels[1].weights[weightOptionNumber].label;
                                if (weightOptionLabel.includes('|')) {
                                    const weightLabelSplit = weightOptionLabel.split('|');
                                    boxWeight.push(weightLabelSplit[0].trim().split(' ')[0]);
                                    if (weightLabelSplit.length > 1) {
                                        boxPrice.push(weightLabelSplit[1].trim().split(' ')[0]);
                                    }
                                } else {
                                    boxPrice.push(this.fruitboxLabels[1].weights[weightOptionNumber].label);
                                    boxWeight.push(this.fruitboxLabels[1].weights[weightOptionNumber].label);
                                }
                            } else {
                                boxPrice.push('-1');
                                boxWeight.push('-1');
                            }
                            const subscriptionOption = fd.get('fivePackSubscriptionFrequencyOption');
                            if ((subscriptionOption ?? 0) > 0 && typeof subscriptionOption === 'string') {
                                const subscriptionOptionNumber: number = parseInt(subscriptionOption) - 1;
                                const subscriptionOptionLabel = this.fruitboxSubscriptionLabels[subscriptionOptionNumber].label;
                                boxFrequency.push(subscriptionOptionLabel);
                            } else {
                                boxFrequency.push('-1');
                            }
                        }
                        if ((fd.get('eightPackAmount') ?? 0) > 0) {
                            boxType.push(this.fruitboxLabels[2].label);
                            boxCount.push(fd.get('eightPackAmount'));
                            const weightOption = fd.get('eightPackWeightOption');
                            if ((weightOption ?? 0) > 0 && typeof weightOption === 'string') {
                                const weightOptionNumber: number = parseInt(weightOption) - 1;
                                const weightOptionLabel = this.fruitboxLabels[2].weights[weightOptionNumber].label;
                                if (weightOptionLabel.includes('|')) {
                                    const weightLabelSplit = weightOptionLabel.split('|');
                                    boxWeight.push(weightLabelSplit[0].trim().split(' ')[0]);
                                    if (weightLabelSplit.length > 1) {
                                        boxPrice.push(weightLabelSplit[1].trim().split(' ')[0]);
                                    }
                                } else {
                                    boxPrice.push(this.fruitboxLabels[2].weights[weightOptionNumber].label);
                                    boxWeight.push(this.fruitboxLabels[2].weights[weightOptionNumber].label);
                                }
                            } else {
                                boxPrice.push('-1');
                                boxWeight.push('-1');
                            }
                            const subscriptionOption = fd.get('eightPackSubscriptionFrequencyOption');
                            if ((subscriptionOption ?? 0) > 0 && typeof subscriptionOption === 'string') {
                                const subscriptionOptionNumber: number = parseInt(subscriptionOption) - 1;
                                const subscriptionOptionLabel = this.fruitboxSubscriptionLabels[subscriptionOptionNumber].label;
                                boxFrequency.push(subscriptionOptionLabel);
                            } else {
                                boxFrequency.push('-1');
                            }
                        }
                        if ((fd.get('exoticPackAmount') ?? 0) > 0) {
                            boxType.push(this.fruitboxLabels[3].label);
                            boxCount.push(fd.get('exoticPackAmount'));
                            const weightOption = fd.get('exoticPackWeightOption');
                            if ((weightOption ?? 0) > 0 && typeof weightOption === 'string') {
                                const weightOptionNumber: number = parseInt(weightOption) - 1;
                                const weightOptionLabel = this.fruitboxLabels[3].weights[weightOptionNumber].label;
                                if (weightOptionLabel.includes('|')) {
                                    const weightLabelSplit = weightOptionLabel.split('|');
                                    boxWeight.push(weightLabelSplit[0].trim().split(' ')[0]);
                                    if (weightLabelSplit.length > 1) {
                                        boxPrice.push(weightLabelSplit[1].trim().split(' ')[0]);
                                    }
                                } else {
                                    boxPrice.push(this.fruitboxLabels[3].weights[weightOptionNumber].label);
                                    boxWeight.push(this.fruitboxLabels[3].weights[weightOptionNumber].label);
                                }
                            } else {
                                boxPrice.push('-1');
                                boxWeight.push('-1');
                            }
                            const subscriptionOption = fd.get('exoticPackSubscriptionFrequencyOption');
                            if ((subscriptionOption ?? 0) > 0 && typeof subscriptionOption === 'string') {
                                const subscriptionOptionNumber: number = parseInt(subscriptionOption) - 1;
                                const subscriptionOptionLabel = this.fruitboxSubscriptionLabels[subscriptionOptionNumber].label;
                                boxFrequency.push(subscriptionOptionLabel);
                            } else {
                                boxFrequency.push('-1');
                            }
                        }
                        dataLayer.push({
                            event: 'formSubmit',
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            trial: (fd.get('trialSubscription') ? 'true' : 'false'),
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            box_type: boxType,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            box_count: boxCount,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            box_weight: boxWeight,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            box_price: boxPrice,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            box_frequency: boxFrequency
                        });
                    } else {
                        dataLayer.push({
                            event: 'formSubmit'
                        });
                    }
                }
                if (res.data.success) {
                    this.success = true;
                } else {
                    this.errorMessage = res.data.details.errorMessage;
                }
            } else {
                this.items.forEach((item) => item.reportCustomValidity());
                for (let i = 0; i < form.elements.length; i++) {
                    // trigger html validation messages
                    (form.elements[i] as HTMLFormElement).reportValidity();
                }
            }
        } catch (err) {
            this.errorMessage = this.serverError;
        } finally {
            this.sending = false;
        }
    }

    checkItemsValidity(): boolean {
        return this.items.every((item) => item.checkCustomValidity());
    }

    toggle() {
        this.checked = !this.checked;
    }

    registerItem(item) {
        this.items.push(item);
    }
}
