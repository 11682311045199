














import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';

@Component({
    components: { Icon }
})
export default class Category extends Vue {
    @Inject({
        default: () => null
    }) readonly registerCategory!: Function;

    @Prop({ required: true }) name: string;
    @Prop({ required: true }) categoryId: string;
    @Prop({ required: true }) categoryKey: string;
    @Prop({ required: true }) categoryActive: string;

    categoryAvailable = true;
    categoryExits = true;
    checked = false;

    mounted() {
        this.registerCategory(this);
    }

    toggle() {
        if (this.categoryAvailable) {
            this.checked = !this.checked;
            this.$emit('toggle', { id: this.categoryId, name: this.name, key: this.categoryKey, checked: this.checked });
        }
    }
}
