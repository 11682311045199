










export default {
    props: {
        name: {
            type: String,
            required: false
        },
        id: {
            type: String,
            required: true
        },
        activeClasses: {
            type: String,
            required: false
        },
        inactiveClasses: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            date: ''
        };
    },
    methods: {
        formatDate(date: Date): string {
            const year = date.getFullYear();
            const month = this.padZero(date.getMonth() + 1);
            const day = this.padZero(date.getDate());
            return `${year}-${month}-${day}`;
        },
        padZero(value: number): string {
            return value.toString().padStart(2, '0');
        }
    }
};
