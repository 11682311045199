
import Vue, { CreateElement } from 'vue';
import { Component, Inject, Prop, Provide } from 'vue-property-decorator';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import Category from './Category.vue';
import Icon from '../base/Icon.vue';

const COLORS = ['text-teal', 'text-lime', 'text-orange', 'text-blue', 'text-purple'];

@Component({
    name: 'SlctFilter',
    components: { Icon, CollapseTransition }
})
export default class Filter extends Vue {
    @Provide('registerCategory') childRegistration = this.registerCategory;
    @Inject({
        default: () => null
    }) readonly registerFilter!: Function;

    @Prop({ required: true }) name: string;
    @Prop({ required: true }) filterKey: string;
    @Prop({ required: true }) categoryId: string;
    @Prop({ required: true }) index: number;
    @Prop({ default: false }) dropdown: boolean;
    @Prop({ default: false }) updateHash: boolean;

    filterActive = true;
    filterExits = true;
    categories: Category[] = [];
    active = false;
    open = true;
    selectedCategories: string[] = [];

    mounted() {
        this.registerFilter(this);
    }

    registerCategory(category: Category) {
        category.$on('toggle', this.onCategoryToggled);
        this.categories.push(category);
    }

    // wrap children in <li> elements
    render(h: CreateElement) {
        // show sub-categories as dropdown
        if (this.dropdown) {
            return h('div', { staticClass: 'py-4 border-t border-opacity-10 border-gray lg:py-8' }, [
                h('div', {
                    staticClass: 'flex items-center cursor-pointer',
                    on: {
                        click: () => {
                            this.toggleAccordion();
                        }
                    }
                }, [
                    h('icon', {
                        staticClass: `hidden w-3 h-4 transform transition-transform fill-current ${COLORS[this.index % COLORS.length]} lg:block`,
                        class: { 'rotate-90': this.open },
                        props: { name: 'chevron' }
                    }),
                    h('div', { staticClass: 'font-bold uppercase lg:ml-4 ' }, this.name)
                ]),
                h('collapse-transition', {}, this.open ? [
                    h('ul', { staticClass: 'mt-10 pl-4 space-y-2 list-inside lg:mt-12' }, [
                        this.$slots.default ? this.$slots.default.filter(x => x.tag).map(el => h('li', [el])) : []
                    ])
                ] : [])
            ]);
        }
        // show category itself as pill
        return h('div', {
            staticClass: 'px-4 py-2 rounded transition-colors text-sm cursor-pointer select-none whitespace-nowrap lg:mb-4',
            class: {
                'bg-white text-gray': !this.active,
                'bg-active-teal text-teal': this.active
            },
            on: {
                click: this.clickHandler
            }
        },
        this.name);
    }

    clickHandler() {
        this.active = !this.active;
        this.onCategoryToggled({ id: this.categoryId, checked: this.active });
    }

    toggleAccordion() {
        this.open = !this.open;
        // checkboxes will be re-mounted, so previous checks are gone.
        // re-check selected categories
        this.updateCheckBoxes();
    }

    updateCheckBoxes() {
        if (this.open) {
            this.$nextTick(() => {
                this.categories.forEach(category => {
                    category.checked = this.selectedCategories.includes(category.categoryId);
                });
            });
        }
    }

    setCategory(category: string) {
        this.active = category === this.categoryId;
    }

    setCategories(categoryKeys: string[]): string[] {
        const categories = this.categories.filter(c => categoryKeys.includes(c.categoryKey)).map(({ categoryId }) => categoryId);
        this.selectedCategories = categories;
        this.updateCheckBoxes();
        return categories;
    }

    clear() {
        this.categories.forEach(category => {
            category.checked = false;
        });
        this.selectedCategories = [];
    }

    // forward event from category
    onCategoryToggled(payload) {
        const id = payload.id;
        const index = this.selectedCategories.indexOf(id);
        if (index >= 0 && !payload.checked) {
            this.selectedCategories.splice(index, 1);
        } else if (index < 0 && payload.checked) {
            this.selectedCategories.push(id);
        }
        this.$emit('toggle-category', {
            id: this.categoryId,
            key: this.filterKey
        });
        this.$emit('filter-changed', {
            filterId: this.categoryId,
            filterKey: this.filterKey,
            categories: this.categories
                .filter(c => this.selectedCategories.includes(c.categoryId))
                .map(c => ({ id: c.categoryId, key: c.categoryKey }))
        });
    }
}
