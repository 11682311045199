const getState = (): string[] => {
    return location.hash
        .replace(/^#|\W$/, '')
        .split('&')
        .filter(x => x.length);
};

const setState = (state: string[]) => {
    window.history.replaceState({}, null, `#${state.join('&')}`);
};

export const addToState = (key: string, value: string) => {
    const state = getState();
    const index = state.findIndex(x => x.split(':').length > 0 && x.split(':')[0] === key);
    if (index < 0) {
        state.push(`${key}:${value}`);
    } else {
        const values = state[index].split(',');
        values.push(value);
        state[index] = values.join(',');
    }
    setState(state);
};

export const removeFromState = (key: string, value = '') => {
    const state = getState();
    const index = state.findIndex(x => x.split(':').length > 0 && x.split(':')[0] === key);
    if (index >= 0) {
        if (value.length) {
            const values = state[index].replace(/[\w-]+:/, '').split(',');
            const valueIndex = values.findIndex(x => x === value);
            if (valueIndex >= 0) {
                values.splice(valueIndex, 1);
                if (values.length > 0) {
                    state[index] = `${key}:${values.join(',')}`;
                } else {
                    state.splice(index, 1);
                }
            }
        } else {
            state.splice(index, 1);
        }
        setState(state);
    }
};
