












import Vue from 'vue';
import { Component, Inject, Prop, Provide } from 'vue-property-decorator';

export interface NavItem {
    label: string;
    link: string;
    target: string;
    level: number;
    _uid: number;
    items: NavItem[];
    icon?: string;
    active?: boolean;
    displayLanguage?: string;
}

@Component
export default class MainNavItem extends Vue implements NavItem {
    @Provide('registerMainNavSubItem') childRegistration = this.register;
    @Prop({ required: true }) link: string;
    @Prop({ required: true }) label: string;
    @Prop({ default: '_self' }) target: string;
    @Prop({ required: true }) level: number;
    @Inject({
        default: () => null
    }) readonly registerMainNavItem!: Function;

    @Inject() readonly mainNavTag!: string;

    items: MainNavItem[] = [];
    open = false;

    mounted() {
        // if the parent is also a nav-item register it there, otherwise use injected register method (form main navigation)
        if (this.$parent.$vnode.tag === this.$vnode.tag) {
            (this.$parent as MainNavItem).register(this);
        } else if (this.$parent.$parent.$vnode && this.$parent.$parent.$vnode.tag === this.$vnode.tag) {
            // use $parent.$parent because the transition component is in-between
            (this.$parent.$parent as MainNavItem).register(this);
        } else if (this.$parent.$vnode.tag === this.mainNavTag || (this.$parent.$parent.$vnode && this.$parent.$parent.$vnode.tag === this.mainNavTag)) {
            this.registerMainNavItem(this);
        }
    }

    onClick() {
        this.$emit('click', this);
    }

    close() {
        if (!this.open) {
            return;
        }
        this.open = false;
        this.items.forEach(item => {
            item.close();
        });
    }

    private register(item) {
        this.items.push(item);
        // item.$on('click', this.onItemClicked);
        item.$on('open', level => this.$emit('open', level));
        item.$on('back', () => {
            this.open = !this.open;
            this.$emit('click', this);
        });
    }
}
