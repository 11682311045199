
































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import Icon from './Icon.vue';

@Component({
    components: {
        Icon,
        CollapseTransition
    }
})
export default class Accordion extends Vue {
    @Prop() title: string;
    @Prop() iconColor: string;
    @Prop({ default: false }) open!: boolean;
    @Prop({ default: '' }) group: string;
    @Prop({ default: false, type: Boolean }) bigLayout: boolean;
    @Prop({ default: false, type: Boolean }) linkLayout: boolean;

    visible = this.open;

    created() {
        this.$root.$on('accordion-open', this.onAccordionOpened);
    }

    beforeDestroy() {
        this.$root.$off('accordion-open', this.onAccordionOpened);
    }

    onAccordionOpened(group) {
        if (this.group === '') {
            return;
        }
        if (this.group === group) {
            this.visible = false;
        }
    }

    toggle() {
        if (!this.visible) {
            this.$root.$emit('accordion-open', this.group);
        }
        this.visible = !this.visible;
        this.$emit('toggle', this.visible);
    }
}

