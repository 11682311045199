









import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Icon from './Icon.vue';

@Component({
    components: {
        Icon
    }
})
export default class BackToTop extends Vue {
    viewportHeight = 0;
    scrollY = 0;

    mounted(): void {
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('resize', () => {
            this.viewportHeight = window.innerHeight;
        });
        this.viewportHeight = window.innerHeight;
    }

    get visible(): boolean {
        return this.scrollY >= this.viewportHeight;
    }

    onScroll(): void {
        this.scrollY = window.scrollY;
    }

    scrollToTop(): void {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }
}
