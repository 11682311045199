












































import { PropType } from 'vue';

interface DropdownOption {
    label: string;
    value: string;
}

export enum ValidityState {
    NONE_SELECTED,
    SELECTED,
    PARTIALLY_SELECTED,
}
export default {
    data() {
        return {
            validityState: ValidityState.NONE_SELECTED,
            amount: 0
        };
    },
    props: {
        subscription: {
            type: Boolean,
            default: false
        },
        imagePath: {
            type: String,
            required: true
        },
        imageAlt: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        weightDropdownName: {
            type: String,
            required: true
        },
        weightLabel: {
            type: String,
            required: true
        },
        weightSelectLabel: {
            type: String,
            required: true
        },
        weightOptions: {
            type: Array as PropType<DropdownOption[]>,
            required: true
        },
        amountLabel: {
            type: String,
            required: true
        },
        amountName: {
            type: String,
            required: true
        },
        subscriptionDropdownName: {
            type: String
        },
        subscriptionLabel: {
            type: String
        },
        subscriptionSelectLabel: {
            type: String
        },
        subscriptionOptions: {
            type: Array as PropType<DropdownOption[]>
        },
        selectionError: {
            type: String,
            required: true
        }
    },
    methods: {
        checkCustomValidity(): boolean {
            const optionValueLength = this.$refs.option.value.length;
            const subscriptionValueLength = this.subscription ? this.$refs.subscription.value.length : 0;
            if (optionValueLength === 0 && this.amount === 0 && subscriptionValueLength === 0) {
                this.validityState = ValidityState.NONE_SELECTED;
            } else if (optionValueLength > 0 && this.amount > 0 && (this.subscription ? subscriptionValueLength > 0 : true)) {
                this.validityState = ValidityState.SELECTED;
            } else {
                this.validityState = ValidityState.PARTIALLY_SELECTED;
            }
            return this.validityState < 2;
        },
        reportCustomValidity() {
            this.checkCustomValidity();
        }
    },
    inject: ['registerProduct'],
    mounted() {
        this.registerProduct(this);
    }
};

