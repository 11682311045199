














import { PropType } from 'vue';

interface CheckboxOption {
    value: string;
    label: string;
}
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        option: {
            type: Object as PropType<CheckboxOption>,
            required: true
        },
        labelWrapperClasses: {
            type: String,
            required: false
        },
        labelClasses: {
            type: String,
            required: false
        },
        slotWrapperClasses: {
            type: String,
            required: false
        },
        activeClasses: {
            type: String,
            required: false
        },
        inactiveClasses: {
            type: String,
            required: false
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            checked: false
        };
    }
};

