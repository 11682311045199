








































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';

@Component({
    components: { Icon }
})
export default class FormDropdown extends Vue {
    @Prop({ required: true }) items: { label: string; value: string }[];
    @Prop({ required: true }) name: string;
    @Prop({ required: true }) label: string;
    @Prop({ default: false }) required: boolean;
    @Prop({ default: false, type: Boolean }) primary: boolean;
    @Prop({ default: false, type: Boolean }) allowDeselect: boolean;

    options: HTMLInputElement[] = [];
    value = '';
    valueLabel = '';
    isOpen = false;

    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    }

    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    onChange() {
        this.isOpen = true;
    }

    select(result) {
        if (this.allowDeselect && this.value === result.value) {
            this.value = '';
            this.valueLabel = '';
        } else {
            this.value = result.value;
            this.valueLabel = result.label;
        }
        this.isOpen = false;
        this.$emit('selected');
    }

    toggle() {
        this.isOpen = !this.isOpen;
    }

    handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
            this.isOpen = false;
        }
    }
}
