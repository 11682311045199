const plugin = require('tailwindcss/plugin');

module.exports = {
    purge: {
        content: [
            './templates/**/*.ftl',
            './includes/templates/*.ftl',
            './macros/*.ftl',
            './lib/*.ftl',
            './ts/**/*.vue'
        ],
        options: {
            safelist: [
                /^(lg:)?joy-/,
                /story/,
                'group-hover:bg-yellow',
                'group-hover:bg-orange',
                'group-hover:bg-purple',
                'group-hover:bg-blue',
                'group-hover:bg-teal',
                'group-hover:bg-lime',
                'group-hover:bg-white',
                'group-hover:bg-fruechtebox-orange',
                'group-hover:bg-fruechtebox-green',
                'group-hover:bg-foodies',
                'group-hover:bg-red',
                'group-hover:bg-foodies',
                'text-opacity-100'
            ] // don't purge any joyline or story classes
        }
    },
    darkMode: false, // or 'media' or 'class'
    corePlugins: {
        container: false
    },
    theme: {
        extend: {
            backgroundImage: {
                main: 'linear-gradient(180deg, #FCFCFE 0%, #ECF0F4 50%);',
                'overlay-dark': 'linear-gradient(90deg, rgba(0, 0, 0, 0.42) 41.52%, rgba(255, 255, 255, 0) 71.47%)',
                'overlay-light': 'linear-gradient(90deg, rgba(255, 255, 255, 0.46) 16.82%, rgba(255, 255, 255, 0) 58.33%)',
                arrow: 'url(\'../assets/img/arrow.svg\')',
                'arrow-white': 'url(\'../assets/img/arrow-white.svg\')',
                'arrow-black': 'url(\'../assets/img/arrow-black.svg\')',
                check: 'url(\'../assets/img/check.svg\')',
                download: 'url(\'../assets/img/download.svg\')',
                'joy-wide-blue-1': 'url(\'../assets/img/joylines/wide/joy-wide-blue-1.webp\')',
                'joy-wide-blue-2': 'url(\'../assets/img/joylines/wide/joy-wide-blue-2.webp\')',
                'joy-wide-blue-3': 'url(\'../assets/img/joylines/wide/joy-wide-blue-3.webp\')',
                'joy-wide-blue-4': 'url(\'../assets/img/joylines/wide/joy-wide-blue-4.webp\')',
                'joy-wide-blue-5': 'url(\'../assets/img/joylines/wide/joy-wide-blue-5.webp\')',
                'joy-wide-blue-6': 'url(\'../assets/img/joylines/wide/joy-wide-blue-6.webp\')',
                'joy-wide-blue-7': 'url(\'../assets/img/joylines/wide/joy-wide-blue-7.webp\')',
                'joy-wide-blue-8': 'url(\'../assets/img/joylines/wide/joy-wide-blue-8.webp\')',
                'joy-wide-blue-9': 'url(\'../assets/img/joylines/wide/joy-wide-blue-9.webp\')',
                'joy-wide-blue-10': 'url(\'../assets/img/joylines/wide/joy-wide-blue-10.webp\')',
                'joy-wide-blue-11': 'url(\'../assets/img/joylines/wide/joy-wide-blue-11.webp\')',
                'joy-wide-purple-1': 'url(\'../assets/img/joylines/wide/joy-wide-purple-1.webp\')',
                'joy-wide-purple-2': 'url(\'../assets/img/joylines/wide/joy-wide-purple-2.webp\')',
                'joy-wide-purple-3': 'url(\'../assets/img/joylines/wide/joy-wide-purple-3.webp\')',
                'joy-wide-purple-4': 'url(\'../assets/img/joylines/wide/joy-wide-purple-4.webp\')',
                'joy-wide-purple-5': 'url(\'../assets/img/joylines/wide/joy-wide-purple-5.webp\')',
                'joy-wide-purple-6': 'url(\'../assets/img/joylines/wide/joy-wide-purple-6.webp\')',
                'joy-wide-purple-7': 'url(\'../assets/img/joylines/wide/joy-wide-purple-7.webp\')',
                'joy-wide-purple-8': 'url(\'../assets/img/joylines/wide/joy-wide-purple-8.webp\')',
                'joy-wide-purple-9': 'url(\'../assets/img/joylines/wide/joy-wide-purple-9.webp\')',
                'joy-wide-purple-10': 'url(\'../assets/img/joylines/wide/joy-wide-purple-10.webp\')',
                'joy-wide-purple-11': 'url(\'../assets/img/joylines/wide/joy-wide-purple-11.webp\')',
                'joy-wide-yellow-1': 'url(\'../assets/img/joylines/wide/joy-wide-yellow-1.webp\')',
                'joy-wide-yellow-2': 'url(\'../assets/img/joylines/wide/joy-wide-yellow-2.webp\')',
                'joy-wide-yellow-3': 'url(\'../assets/img/joylines/wide/joy-wide-yellow-3.webp\')',
                'joy-wide-yellow-4': 'url(\'../assets/img/joylines/wide/joy-wide-yellow-4.webp\')',
                'joy-wide-yellow-5': 'url(\'../assets/img/joylines/wide/joy-wide-yellow-5.webp\')',
                'joy-wide-yellow-6': 'url(\'../assets/img/joylines/wide/joy-wide-yellow-6.webp\')',
                'joy-wide-yellow-7': 'url(\'../assets/img/joylines/wide/joy-wide-yellow-7.webp\')',
                'joy-wide-yellow-8': 'url(\'../assets/img/joylines/wide/joy-wide-yellow-8.webp\')',
                'joy-wide-yellow-9': 'url(\'../assets/img/joylines/wide/joy-wide-yellow-9.webp\')',
                'joy-wide-yellow-10': 'url(\'../assets/img/joylines/wide/joy-wide-yellow-10.webp\')',
                'joy-wide-yellow-11': 'url(\'../assets/img/joylines/wide/joy-wide-yellow-11.webp\')',
                'joy-small-blue-1': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-blue-1.webp\')',
                'joy-small-blue-2': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-blue-2.webp\')',
                'joy-small-blue-3': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-blue-3.webp\')',
                'joy-small-blue-4': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-blue-4.webp\')',
                'joy-small-blue-5': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-blue-5.webp\')',
                'joy-small-blue-6': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-blue-6.webp\')',
                'joy-small-purple-1': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-purple-1.webp\')',
                'joy-small-purple-2': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-purple-2.webp\')',
                'joy-small-purple-3': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-purple-3.webp\')',
                'joy-small-purple-4': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-purple-4.webp\')',
                'joy-small-purple-5': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-purple-5.webp\')',
                'joy-small-purple-6': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-purple-6.webp\')',
                'joy-small-yellow-1': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-yellow-1.webp\')',
                'joy-small-yellow-2': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-yellow-2.webp\')',
                'joy-small-yellow-3': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-yellow-3.webp\')',
                'joy-small-yellow-4': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-yellow-4.webp\')',
                'joy-small-yellow-5': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-yellow-5.webp\')',
                'joy-small-yellow-6': 'url(\'../assets/img/joylines/small-hero/joy-small-hero-yellow-6.webp\')',
                'joy-hero-blue-1': 'url(\'../assets/img/joylines/hero/joy-hero-blue-1.webp\')',
                'joy-hero-blue-2': 'url(\'../assets/img/joylines/hero/joy-hero-blue-2.webp\')',
                'joy-hero-blue-3': 'url(\'../assets/img/joylines/hero/joy-hero-blue-3.webp\')',
                'joy-hero-purple-1': 'url(\'../assets/img/joylines/hero/joy-hero-purple-1.webp\')',
                'joy-hero-purple-2': 'url(\'../assets/img/joylines/hero/joy-hero-purple-2.webp\')',
                'joy-hero-purple-3': 'url(\'../assets/img/joylines/hero/joy-hero-purple-3.webp\')',
                'joy-hero-yellow-1': 'url(\'../assets/img/joylines/hero/joy-hero-yellow-1.webp\')',
                'joy-hero-yellow-2': 'url(\'../assets/img/joylines/hero/joy-hero-yellow-2.webp\')',
                'joy-hero-yellow-3': 'url(\'../assets/img/joylines/hero/joy-hero-yellow-3.webp\')',
                'joy-foodies': 'url(\'../assets/img/joylines/hero/joy-hero-foodies.webp\')',
                'joy-error': 'url(\'../assets/img/joylines/404_error-joyline.png\')',
                'pr-creme': 'url(\'../assets/img/pelican/creme.webp\')',
                'pr-green': 'url(\'../assets/img/pelican/green.webp\')',
                'pr-purple': 'url(\'../assets/img/pelican/purple.webp\')',
                'pr-white': 'url(\'../assets/img/pelican/white.webp\')'
            },
            boxShadow: {
                orange: '0px 2px 16px rgba(255, 145, 36, 0.1)',
                lime: '0px 2px 16px rgba(183, 222, 34, 0.1)',
                blue: '0px 2px 16px rgba(107, 195, 207, 0.1)',
                teal: '0px 2px 16px rgba(0, 120, 143, 0.1)',
                purple: '0px 2px 16px rgba(72, 0, 115, 0.1)',
                top: '0px 1px 9px 0px rgba(0, 0, 0, 0.16);'
            },
            colors: {
                white: '#FFFFFF',
                'very-light': '#FCFCFE',
                light: '#F7FAFB',
                'inactive-gray': '#F0F0F4',
                'form-gray': '#C4C4C4',
                'active-teal': '#D3EDF1',
                gray: '#242429',
                'dark-gray': '#28292E',
                'light-gray': '#D0D5DD',
                'dark-red-pressed': '#B90519',
                'dark-red': '#C2061A',
                'light-red': '#ED5F6E',
                red: '#E10718',
                orange: '#FF9E25',
                yellow: '#FCE500',
                lime: '#C0E021',
                blue: '#7EC9D5',
                teal: '#1A839A',
                foodies: '#009410',
                starbucks: '#006241',
                purple: '#50037F',
                lavazza: '#FF00FF',
                nescafe: '#FF00FF',
                miofino: '#FF00FF',
                pelican: {
                    red: '#EB0000',
                    light: '#FFF9EF',
                    coffee: '#5C1327',
                    lila: {
                        light: '#DBD2EA',
                        dark: '#A088D6'
                    },
                    green: {
                        light: '#CFD600',
                        dark: '#17673A'
                    }
                },
                fruechtebox: {
                    green: '#A4C408',
                    orange: '#EDA800'
                }
            },
            fontFamily: {
                sans: ['Helvetica Neue', 'Helvetica', 'Roboto', 'sans-serif'],
                pelican: ['Jost', 'Roboto', 'sans-serif'],
                heading: ['Prometo', 'sans-serif']
            },
            fontSize: {
                'story-intro-mobile': ['1.4375rem', '2.0625rem'],
                'story-intro': ['1.75rem', '2.375rem'],
                'h2-mobile': ['1.75rem', '2.5rem'],
                '4xl': ['2rem', '2.5rem'],
                '5xl': ['2.5rem', '3rem'],
                '6xl': ['3rem', '3.5rem'],
                '7xl': ['3.5rem', '4rem'],
                '8xl': ['3.75rem', '4.375rem']
            },
            fontWeight: {
                extralight: '250'
            },
            gridAutoColumns: {
                brands: 'minmax(0, 7.5rem)'
            },
            width: {
                'image-oversize': '250%'
            },
            height: {
                nav: 'calc(100vh - 100%)',
                150: '600px',
                117.5: '470px',
                113: '452px',
                110: '440px'
            },
            minWidth: {
                40: '160px'
            },
            maxWidth: {
                'screen-lg': '1080px',
                'screen-xl': '1444px'
            },
            maxHeight: {
                42: '10.5rem'
            },
            minHeight: {
                stage: '724px',
                'stage-subtle': '320px',
                135: '540px',
                form: '1040px',
                jobs: '1550px'
            },
            margin: {
                '1/2': '50%'
            },
            padding: {
                '3/2': '66.66%',
                '4/3': '75%',
                '4/5': '125%',
                '16/9': '56.25%',
                '9/16': '177.77%',
                full: '100%'
            },
            typography: theme => ({
                DEFAULT: {
                    css: {
                        color: 'inherit',
                        'ul > li::before': {
                            backgroundColor: theme('colors.teal'),
                            width: '0.3125rem',
                            height: '0.3125rem'
                        },
                        a: {
                            color: theme('colors.red')
                        },
                        strong: {
                            color: 'inherit'
                        }
                    }
                },
                story: {
                    css: {
                        fontSize: theme('fontSize.lg')[0],
                        lineHeight: theme('fontSize.lg')[1],
                        a: {
                            color: theme('colors.purple'),
                            textDecoration: 'none',
                            fontWeight: 'inherit'
                        },
                        strong: {
                            display: 'block',
                            fontWeight: 'inherit',
                            marginLeft: '-1rem',
                            paddingLeft: '1rem',
                            borderLeft: '1px solid #E40019'
                        }
                    }
                }
            }),
            scale: {
                flip: '-1' // negative keys don't work for scale (tw issue)
            },
            screens: {
                '2xl': '1444px'
            },
            spacing: {
                17: '4.25rem'
            }
        }
    },
    variants: {
        extend: {
            backgroundColor: ['active', 'important', 'group-hover'],
            backgroundPosition: ['important'],
            backgroundSize: ['important'],
            borderColor: ['active', 'valid'],
            borderOpacity: ['valid'],
            filter: ['active-slide', 'hover', 'group-hover'],
            invert: ['hover', 'group-hover'],
            brightness: ['hover', 'group-hover'],
            fontWeight: ['hover', 'group-hover'],
            fontSize: ['important'],
            inset: ['important'],
            opacity: ['active-slide', 'group-hover'],
            height: ['important'],
            margin: ['important'],
            minHeight: ['important'],
            scale: ['group-hover'],
            textColor: ['active', 'important', 'hover'],
            textOpacity: ['important'],
            translate: ['group-hover'],
            transform: ['important'],
            transitionProperty: ['group-hover']
        }
    },
    plugins: [
        require('@tailwindcss/line-clamp'),
        require('@tailwindcss/typography'),
        plugin(({ addVariant, addUtilities, e }) => {
            // add variant to make utilities use !important
            addVariant('important', ({ container }) => {
                container.walkRules(rule => {
                    rule.selector = `.\\!${rule.selector.slice(1)}`;
                    rule.walkDecls(decl => {
                        decl.important = true;
                    });
                });
            });
            addVariant('active-slide', ({ modifySelectors, separator }) => {
                modifySelectors(({ className }) => {
                    return `.slide-active .${e(`active-slide${separator}${className}`)}`;
                });
            });
            addVariant('valid', ({ modifySelectors, separator }) => {
                modifySelectors(({ className }) => {
                    return `.${e(`valid${separator}${className}`)}:valid:not(:placeholder-shown)`;
                });
            });
            // joyline styles
            const joylineColors = ['yellow', 'blue', 'purple'];
            const suffixes = len => [].concat(...joylineColors.map(color =>
                new Array(len)
                    .fill(0)
                    .map((_, i) => `${color}-${i + 1}`))
            );
            addUtilities(
                [].concat(
                    // wide joylines (11 variants)
                    ...suffixes(11).map(name => ({
                        [`.joy-wide-${name}`]: {
                            [`@apply absolute -top-6 left-0 w-full bg-no-repeat pointer-events-none lg:top-1/2 lg:transform lg:-translate-y-1/2 bg-joy-wide-${name}`]: {},
                            backgroundSize: '200% auto',
                            height: 'calc(100% + 3rem)',
                            zIndex: 9,
                            '@media (min-width: 1024px)': {
                                backgroundSize: '3000px auto',
                                minHeight: '1000px'
                            }
                        }
                    })),
                    // hero joylines (3 variants)
                    ...suffixes(3).map(name => ({
                        [`.joy-hero-${name}`]: {
                            [`@apply absolute top-0 left-0 z-10 w-full h-56 bg-right bg-no-repeat pointer-events-none bg-joy-hero-${name}`]: {},
                            backgroundSize: 'auto 100%',
                            '@media (min-width: 1024px)': {
                                height: 'calc(100% + 1.5rem)'
                            }
                        }
                    })),
                    // subtle hero joylines (6 variants)
                    ...suffixes(6).map(name => ({
                        [`.joy-hero-small-${name}`]: {
                            [`@apply absolute top-0 left-0 z-0 w-full h-56 bg-no-repeat pointer-events-none lg:top-1/2 lg:transform lg:-translate-y-1/2 lg:h-full bg-joy-small-${name}`]: {},
                            backgroundSize: 'auto 100%'
                        }
                    })),
                    // foodies joyline
                    {
                        '.joy-foodies': {
                            '@apply absolute top-0 left-0 z-0 w-full h-56 bg-left bg-no-repeat pointer-events-none lg:top-1/2 transform translate-y-0 lg:-translate-y-1/2 lg:h-full bg-joy-foodies': {},
                            backgroundSize: 'auto 100%',
                            backgroundPosition: '-7rem center',
                            '@media (min-width: 1024px)': {
                                backgroundPosition: '-16rem center'
                            }
                        }
                    },
                    // error joyline
                    {
                        '.joy-error': {
                            '@apply absolute -top-6 left-0 w-full bg-no-repeat pointer-events-none lg:top-1/2 lg:transform lg:-translate-y-1/2 bg-joy-error': {},
                            backgroundSize: '200% auto',
                            height: 'calc(100% + 3rem)',
                            zIndex: 9,
                            '@media (min-width: 1024px)': {
                                backgroundSize: '3000px auto',
                                minHeight: '1000px'
                            }
                        }
                    }
                ), ['responsive']
            );
        })
    ]
};
