
import Vue, { CreateElement } from 'vue';
import { Component, Prop, Provide, Ref } from 'vue-property-decorator';
import Swiper, { SwiperOptions, Autoplay } from 'swiper';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config.js';
import { TailwindConfig } from 'tailwindcss/tailwind-config';

@Component
export default class Slider extends Vue {
    @Ref('container') container;

    @Prop({ default: 'horizontal' }) direction!: 'horizontal' | 'vertical';
    @Prop({ type: Boolean, default: false }) loop!: boolean;
    @Prop({ default: 0 }) gap: number | { [breakpoint: string]: number };
    @Prop({ default: '' }) containerClasses: string;
    @Prop({ default: '' }) slideClasses: string;
    @Prop({ default: '' }) wrapperClasses: string;
    @Prop({ default: '' }) editModeWrapperClasses: string;
    @Prop({ default: 'div' }) slideTag: string;
    @Prop({ default: 'div' }) wrapperTag: string;
    @Prop({ default: 0 }) start!: number;
    @Prop({ default: 300 }) speed!: number;
    @Prop({ default: 0 }) slidesOffsetAfter!: number | { [breakpoint: string]: number };
    @Prop({ default: false }) centered!: number | { [breakpoint: string]: boolean };
    @Prop({ type: Boolean, default: false }) autoplay!: boolean;
    @Prop({ type: Boolean, default: false }) autoHeight!: boolean;
    @Prop({ type: Boolean, default: false }) editMode: boolean;
    @Prop({ default: 'auto' }) slidesPerView: number | 'auto' | { [breakpoint: string]: number | 'auto' };
    @Provide('registerSlide') childRegistration = this.register;

    slides = [];
    swiper = null;
    index = this.start;
    options: SwiperOptions = {
        watchOverflow: true,
        wrapperClass: 'slider-wrapper',
        slideClass: 'slider-slide',
        observer: true,
        loop: this.loop || this.autoplay, // always loop in autoplay
        speed: this.speed,
        autoplay: this.autoplay ? { delay: 5000 } : false,
        autoHeight: this.autoHeight,
        direction: this.direction,
        touchReleaseOnEdges: true,
        allowSlidePrev: false,
        initialSlide: this.start,
        observeParents: true,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        slideActiveClass: 'slide-active',
        slideToClickedSlide: true
    };

    twConfig: TailwindConfig = resolveConfig(tailwindConfig);

    mounted() {
        if (this.editMode) {
            return;
        }
        this.setBreakpointOption(this.direction, 'direction');
        this.setBreakpointOption(this.gap, 'spaceBetween');
        this.setBreakpointOption(this.slidesPerView, 'slidesPerView');
        this.setBreakpointOption(this.slidesOffsetAfter, 'slidesOffsetAfter');
        this.setBreakpointOption(this.centered, 'centeredSlides');
        if (this.autoplay) {
            Swiper.use([Autoplay]);
        }
        this.swiper = new Swiper(this.container, this.options);
        this.swiper.on('activeIndexChange', this.indexChanged);
    }

    indexChanged({ activeIndex, realIndex }) {
        this.index = (this.loop || this.autoplay) ? realIndex : activeIndex;
        this.$emit('index-changed', this.index);
    }

    next() {
        if (this.swiper) {
            if (this.loop) {
                this.index = this.index + 1 >= this.swiper.slides.length ? 0 : this.index + 1;
            } else if (this.index + 1 < this.swiper.slides.length) {
                this.index++;
            }
            this.swiper.slideTo(this.index);
        }
    }

    prev() {
        if (this.swiper) {
            if (this.loop) {
                this.index = this.index > 0 ? this.index - 1 : this.swiper.slides.length - 1;
            } else if (this.index > 0) {
                this.index--;
            }
            this.swiper.slideTo(this.index);
        }
    }

    goTo(index) {
        if (this.swiper) {
            this.swiper.slideTo(index);
            this.index = index;
        }
    }

    render(h: CreateElement) {
        return h('div', { staticClass: 'w-full h-full' }, [
            h('div', {
                staticClass: ['slider-container h-full w-full overflow-hidden', this.containerClasses].filter(x => x.length).join(' '),
                ref: 'container'
            }, [
                h(this.wrapperTag, {
                    staticClass: ['slider-wrapper', this.editMode ? this.editModeWrapperClasses : 'flex flex-nowrap h-full', this._wrapperClasses, this.wrapperClasses].filter(x => x.length).join(' '),
                    class: { 'items-start': this.autoHeight }
                },
                this.$slots.default ? this.$slots.default.filter(x => x.tag).map((el, i) => h(this.slideTag, {
                    staticClass: `slider-slide flex-shrink-0 max-w-full ${this.slideClasses}${this.index === i ? ' slide-active' : ''}`
                }, [el])) : [])
            ]),
            (this.$scopedSlots.pagination && !this.editMode) ? this.$scopedSlots.pagination({
                index: this.index + 1,
                total: this.numSlides,
                teaser: this.currentSlide,
                go: this.goTo,
                prev: this.prev,
                next: this.next,
                uid: this._uid
            }) : null,
            (this.$scopedSlots.controls) ? this.$scopedSlots.controls({
                prev: this.prev,
                next: this.next,
                total: this.numSlides,
                index: this.index
            }) : null
        ]);
    }

    // called by child slides
    register(slide) {
        slide.$on('click', e => {
            const index = this.slides.findIndex(x => x._id === e._id);
            if (index >= 0 && index < this.slides.length) {
                this.goTo(index);
            }
        });
        this.slides.push(slide);
        if (this.$children.filter(this.filterSlides).length === this.slides.length) {
            // do init
        }
    }

    breakpointSize(name: string): number {
        if (Object.prototype.hasOwnProperty.call(this.twConfig.theme.screens, name)) {
            return parseInt(this.twConfig.theme.screens[name]);
        }
        return -1;
    }

    setBreakpointOption(value, name) {
        const breakpoints = this.options.breakpoints || {};
        if (typeof value === 'number' || typeof value === 'string') {
            this.options[name] = value;
            return;
        }
        Object.entries(value).forEach(x => {
            const bp = this.breakpointSize(x[0]);
            if (bp > 0) {
                if (!Object.prototype.hasOwnProperty.call(breakpoints, bp)) {
                    breakpoints[bp] = {};
                }
                breakpoints[bp][name] = x[1];
            } else {
                this.options[name] = x[1];
            }
        });
        if (!this.options.breakpoints) {
            this.options.breakpoints = breakpoints;
        }
    }

    // check children to only count slide components
    filterSlides(child: Vue) {
        return child.$vnode && child.$vnode.componentOptions && child.$vnode.componentOptions.tag === 'slide';
    }

    get numSlides() {
        if (this.$slots.default) {
            return this.$slots.default.filter(x => x.tag).length;
        }
        return 0;
    }

    get currentSlide() {
        const index = (this.index - this.start) < 0 ? this.slides.length - 1 : this.index - this.start;
        if (this.slides.length && this.slides.length > index) {
            return this.slides[index];
        }
        return null;
    }

    get _wrapperClasses() {
        if (typeof this.direction === 'string') {
            return this.direction === 'vertical' ? 'flex-col' : 'flex-row';
        }
        // md:flex-col lg:flex-col xl:flex-col md:flex-row lg:flex-row xl:flex-row
        const classes = [];
        Object.entries(this.direction).forEach(x => {
            const bp = this.breakpointSize(x[0]);
            const dir = x[1] === 'vertical' ? 'flex-col' : 'flex-row';
            if (bp > 0) {
                classes.push(`${x[0]}:${dir}`);
            } else {
                classes.push(dir);
            }
        });
        return classes.join(' ');
    }
}

