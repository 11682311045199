






import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';

@Component
export default class StoryItem extends Vue {
    @Inject({
        default: () => null
    }) readonly registerStory!: Function;

    show = true;

    mounted() {
        this.registerStory(this);
    }
}
