















import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';

@Component
export default class Category extends Vue {
    @Inject({
        default: () => null
    }) readonly registerCategory!: Function;

    @Prop({ required: true }) name: string;
    @Prop({ required: true }) categoryId: string;
    @Prop({ required: true }) categoryKey: string;

    categoryAvailable = true;
    categoryExits = true;
    checked = false;

    mounted() {
        this.registerCategory(this);
    }

    toggle() {
        this.checked = !this.checked;
        this.$emit('toggle', { id: this.categoryId, key: this.categoryKey, checked: this.checked });
    }
}
