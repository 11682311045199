




















































import Vue from 'vue';
import { Component, Prop, Provide } from 'vue-property-decorator';
import OverviewItem from './OverviewItem.vue';
import Filter from './Filter.vue';
import Icon from '../base/Icon.vue';
import { addToState, removeFromState } from '../../utils';

const LIMIT = 9;

@Component({
    components: { Icon }
})
export default class Overview extends Vue {
    @Provide('registerOverviewItem') childRegistration = this.registerItem;
    @Provide('registerFilter') filterRegistration = this.registerFilter;

    @Prop({ default: false, type: Boolean }) showSubCategories;
    @Prop() clearFiltersLabel: string;
    @Prop() filtersLabel: string;
    @Prop() applyLabel: string;
    @Prop({ default: '' }) parentFilter: string;
    @Prop({ required: true }) showMoreLabel: string;
    @Prop({ default: false, type: Boolean }) isArticles;

    items: OverviewItem[] = [];
    filters: Filter[] = [];
    possibleFilters: string[] = [];
    selectedCategories: { id: string; key: string }[] = [];
    selectedFilters: { id: string; categories: string[] }[] = [];
    selectedFiltersBuffer: { id: string; categories: string[] }[] = [];
    filtersOpen = false;
    applyLimit = true;
    limited = false;

    registerItem(product: OverviewItem) {
        this.items.push(product);
        if (this.$children.filter(this.filterItems).length === this.items.length) {
            this.filterProducts();
        }
    }

    registerFilter(filter: Filter) {
        filter.$on('toggle-category', ({ id, key }) => this.toggleCategory(id, key));
        filter.$on('filter-changed', ({
            filterId,
            filterKey,
            categories
        }) => this.updateFilter(filterId, filterKey, categories));
        this.selectedFilters.push({ id: filter.categoryId, categories: [] });
        this.filters.push(filter);
        this.readHash(filter);
    }

    // check children to only count slide components
    filterItems(child: Vue) {
        return child.$vnode && child.$vnode.componentOptions && child.$vnode.componentOptions.tag === 'slct-item';
    }

    toggleCategory(id: string, key: string) {
        if (this.showSubCategories || !id || !key) {
            return;
        }
        const index = this.selectedCategories.findIndex(x => x.id === id);
        if (index >= 0) {
            this.selectedCategories.splice(index, 1);
        } else {
            this.selectedCategories.push({ id, key });
        }
        this.updateHash(this.parentFilter, this.selectedCategories);

        this.filterProducts();
    }

    updateFilter(id: string, key: string, categories: { id: string; key: string }[]) {
        if (!this.showSubCategories) {
            return;
        }
        let filter;
        if (this.filtersOpen) {
            filter = this.selectedFiltersBuffer.find(filter => filter.id === id);
            filter.categories = categories.map(({ id }) => id);
        } else {
            filter = this.selectedFilters.find(filter => filter.id === id);
            filter.categories = categories.map(({ id }) => id);
            this.filterProducts();
        }
        this.updateHash(key, categories);
        this.$forceUpdate();
    }

    clearFilters() {
        this.filters.forEach(filter => {
            filter.clear();
            this.updateHash(filter.filterKey);
        });
        this.selectedCategories = [];
        this.selectedFilters.forEach(filter => {
            filter.categories = [];
        });
        this.filterProducts();
    }

    clearFiltersBuffer() {
        this.selectedFiltersBuffer.forEach(filter => {
            filter.categories = [];
        });
        this.filters.forEach(filter => {
            filter.clear();
        });
        this.updateCheckboxes();
    }

    showMore() {
        this.applyLimit = false;
        this.filterProducts();
    }

    applyFilters() {
        if (!this.filtersOpen) {
            return;
        }
        this.selectedFilters = JSON.parse(JSON.stringify(this.selectedFiltersBuffer));
        this.selectedFilters.forEach(fltr => {
            const filter = this.filters.find(x => x.categoryId === fltr.id);
            this.updateHash(filter.filterKey, filter.categories.filter(c => fltr.categories.includes(c.categoryId)).map(({
                categoryId,
                categoryKey
            }) => ({
                id: categoryId,
                key: categoryKey
            })));
        });
        this.filterProducts();
        this.toggleMobileFilters();
    }

    filterProducts() {
        // if articles, only show if have all selectedCategories
        if (this.isArticles) {
            this.items.forEach((product: OverviewItem) => {
                // only product.show is true when has limit
                product.showFilter = product.show = this.selectedCategories.every(({ id: categoryId }) =>
                    product.categories.includes(categoryId)
                );
            });
        // if not articles, show if have one of the categories
        } else {
            if (this.showSubCategories) {
                this.items.forEach((product: OverviewItem) => {
                    product.showFilter = product.show = this.selectedFilters.every(filter =>
                        filter.categories.some(categoryId =>
                            product.categories.some((category: string) => category === categoryId)
                        ) || filter.categories.length === 0
                    );
                });
            } else {
                this.items.forEach((product: OverviewItem) => {
                    product.showFilter = product.show = this.selectedCategories.some(({ id: categoryId }) =>
                        product.categories.some((category: string) => category === categoryId)
                    ) || this.selectedCategories.length === 0;
                });
            }
        }
        // limit items
        if (this.applyLimit) {
            const filtered = this.items.filter(item => item.show).length;
            let count = 0;
            this.items.forEach((item: OverviewItem) => {
                item.show = item.show && count < LIMIT;
                if (item.show) {
                    count++;
                }
            });
            this.limited = filtered > this.items.filter(item => item.show).length;
        }
        this.checkPossibleFilters();
    }

    // check witch categories can still be active and have results for articles
    checkPossibleFilters() {
        this.possibleFilters = [];
        // go to all filters
        this.filters.forEach(filter => {
            let filterActive = false;
            let filterExits = false;
            // go to all categories of the filter
            filter.categories.forEach(categoryFilter => {
                let categoryAvailable = false;
                let categoryExits = false;
                // check all items
                this.items.forEach((product: OverviewItem) => {
                    if (product.categories.includes(categoryFilter.categoryId)) {
                        // if one item has the category the filter and the category exists
                        filterExits = true;
                        categoryExits = true;
                        // if is article and the product is showed the category is available
                        if (product.showFilter && this.isArticles) {
                            categoryAvailable = true;
                            filterActive = true;
                        // if is not article and is using subcategories check if the combination of all filters would have results
                        } else if (this.showSubCategories) {
                            const hasCat = this.selectedFilters.every(filterProd =>
                                filterProd.categories.some(categoryId =>
                                    // current active filters or the one we are testing
                                    (product.categories.some((category: string) => (category === categoryId || (filterProd.id === filter.categoryId && categoryFilter.categoryId === category))))
                                ) || filterProd.categories.length === 0
                            );
                            categoryAvailable = hasCat || categoryAvailable;
                            filterActive = hasCat || filterActive;
                        }
                    }
                });
                categoryFilter.categoryAvailable = categoryAvailable;
                categoryFilter.categoryExits = categoryExits;
            });
            filter.filterActive = filterActive;
            filter.filterExits = filterExits;
        });
    }

    updateCheckboxes() {
        const selectedFilters = this.filtersOpen ? this.selectedFiltersBuffer : this.selectedFilters;
        this.filters.forEach(filter => {
            filter.categories.forEach(category => {
                category.checked = selectedFilters.some(filter =>
                    filter.categories.some(cat => cat === category.categoryId)
                );
            });
        });
    }

    toggleMobileFilters() {
        this.filtersOpen = !this.filtersOpen;
        this.selectedFiltersBuffer = JSON.parse(JSON.stringify(this.selectedFilters));
        this.updateCheckboxes();
        document.body.classList.toggle('overflow-hidden', this.filtersOpen);
        document.body.classList.toggle('lg:overflow-auto', this.filtersOpen);
        const header: HTMLElement = document.getElementsByTagName('header').item(0);
        header.classList.toggle('z-50');
        header.classList.toggle('z-0');
    }

    readHash(filter) {
        const hash = location.hash.replace(/^#/, '');
        const filters = hash
            .split('&')
            .filter(x => x.includes(':'))
            .map(x => x.split(':'))
            .filter(x => x.length === 2);
        if (this.showSubCategories) {
            const toSet = filters.find(x => x.length === 2 && x[0] === filter.filterKey);
            if (toSet) {
                if (this.showSubCategories) {
                    this.selectedFilters.find(x => x.id === filter.categoryId).categories = filter.setCategories(toSet[1].split(','));
                } else {
                }
            }
        } else if (filters.some(x => x.includes(filter.filterKey))) {
            const cat = { id: filter.categoryId, key: filter.filterKey };
            this.selectedCategories.push(cat);
            filter.setCategory(cat.id);
        } else if (this.isArticles) {
            const toSet = filters.find(x => x.length === 2);
            if (toSet) {
                const cats = filter.setCategories(toSet[1].split(','));
                cats.forEach(cat => {
                    if (cat.id && cat.key) {
                        this.selectedCategories.push(cat);
                    }
                });
            }
        }
    }

    updateHash(filter: string, categories: { id: string; key: string }[] = []) {
        removeFromState(filter);
        categories.forEach(cat => {
            addToState(filter, cat.key);
        });
    }

    get activeFilters() {
        return this.selectedFilters.reduce((sum, filter) => sum + filter.categories.length, 0);
    }

    get activeFiltersBuffer() {
        return this.selectedFiltersBuffer.reduce((sum, filter) => sum + filter.categories.length, 0);
    }

    get filterActive() {
        return this.activeFilters > 0;
    }
}
