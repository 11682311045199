/* eslint-disable dot-notation */
import Vue from 'vue';
import './component-loader';

/* Plugins */

// define context- and resource path globally for vue components
Vue.prototype.$contextPath = window['contextPath'];
Vue.prototype.$resourcePath = window['resourcePath'];
Vue.prototype.$site = window['siteName'];
Vue.prototype.$lang = window['lang'];
Vue.prototype.$prototype = window['prototype'];

const app = new Vue({
    el: '#app',
    comments: true
});

// declare types for global variables or prod build will fail
declare module 'vue/types/vue' {
    interface Vue {
        $contextPath: string;
        $resourcePath: string;
        $site: string;
        $lang: string;
        $prototype: boolean;
        _uid: number;
    }
}

declare global{
    interface Window {
        dataLayer: any;
    }
}
