













import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Helper from '../../libs/Helper';

@Component
export default class YtVideo extends Vue {
    @Prop({ required: true }) videoId: string;
    @Prop({ default: false, type: Boolean }) original: boolean;
    @Prop({ default: false, type: Boolean }) product: boolean;
    @Prop({ default: false, type: Boolean }) highlight: boolean;
    @Prop({ default: false, type: Boolean }) slide: boolean;
    @Prop({ default: false, type: Boolean }) editMode: boolean;
    @Prop({ default: false, type: Boolean }) author: boolean;

    performanceCookie = false;

    created() {
        this.checkCookie();
        window.addEventListener('CookiebotOnAccept', () => {
            this.checkCookie();
        });
    }

    checkCookie() {
        const cookie = Helper.getCookie('CookieConsent');
        this.performanceCookie = cookie && cookie.includes('marketing:true');
    }
}
