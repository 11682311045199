














import Vue from 'vue';
import { Component, Prop, Provide } from 'vue-property-decorator';
import StoryItem from './StoryItem.vue';
import Icon from '../base/Icon.vue';

@Component({
    components: { Icon }
})
export default class StoriesOverview extends Vue {
    @Provide('registerStory') childRegistration = this.registerItem;

    @Prop({ required: true }) loadMoreLabel: string;
    @Prop({ required: true }) showLessLabel: string;
    @Prop({ default: true }) limitItems: boolean;
    @Prop({ default: false }) editMode: boolean;

    items: StoryItem[] = [];
    limit = Infinity;

    get isLimited() {
        return this.limit < this.items.length && this.items.length > 4;
    }

    registerItem(story: StoryItem) {
        this.items.push(story);
        if (this.$children.filter(this.filterItems).length === this.items.length) {
            this.limit = this.limitItems ? 4 : this.items.length;
            this.applyLimit();
        }
    }

    // check children to only count story components
    filterItems(child: Vue) {
        return child.$vnode && child.$vnode.componentOptions && child.$vnode.componentOptions.tag === 'story-item';
    }

    applyLimit() {
        this.items.forEach((item: StoryItem, index) => {
            item.show = index < this.limit;
        });
    }

    moreOrLess() {
        if (this.limit < this.items.length) {
            this.limit = Math.min(this.limit + 6, this.items.length);
        } else {
            this.limit = 4;
        }
        this.applyLimit();
    }
}
