

































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class AutocompleteInput extends Vue {
    @Prop({ required: true }) items: string[];
    @Prop({ required: true }) name: string;
    @Prop({ required: true }) label: string;
    @Prop({ default: false }) required: boolean;

    isOpen = false;
    results = [];
    search = '';
    arrowCounter = 0;

    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    }

    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    onChange() {
        this.$emit('input', this.search);
        this.filterResults();
        this.isOpen = true;
    }

    filterResults() {
        this.results = this.items.filter(item => {
            const regex = new RegExp(`(^|\\s)(${this.search.toLowerCase()})`);
            return item.toLowerCase().match(regex);
            // return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
        });
    }

    setResult(result) {
        this.search = result;
        this.isOpen = false;
    }

    onArrowDown() {
        if (this.arrowCounter < this.results.length) {
            this.arrowCounter = this.arrowCounter + 1;
        }
    }

    onArrowUp() {
        if (this.arrowCounter > 0) {
            this.arrowCounter = this.arrowCounter - 1;
        }
    }

    onEnter() {
        this.search = this.results[this.arrowCounter];
        this.isOpen = false;
        this.arrowCounter = -1;
    }

    handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
            this.isOpen = false;
            this.arrowCounter = -1;
        }
    }

    @Watch('items')
    onItemsCHanged(val, oldValue) {
        if (val.length !== oldValue.length) {
            this.results = val;
        }
    }
}
