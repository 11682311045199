














import Vue from 'vue';
import { Component, Provide } from 'vue-property-decorator';
import AccordionTeaserItem from './AccordionTeaserItem.vue';

@Component
export default class AccordionTeaser extends Vue {
    @Provide('register') childRegistration = this.registerItem;

    items: AccordionTeaserItem[] = [];
    currentImage = '';

    registerItem(item: AccordionTeaserItem) {
        this.items.push(item);
        item.group = `${this._uid}`;
        item.$on('open', this.onAccordionOpened);
        if (this.$children.filter(this.filterItems).length === this.items.length && this.items.length > 0) {
            // all items registered -> open first one
            setTimeout(() => {
                this.items[0].open();
            }, 400);
        }
    }

    filterItems(child: Vue) {
        return child.$vnode && child.$vnode.componentOptions && child.$vnode.componentOptions.tag === 'accordion-teaser-item';
    }

    onAccordionOpened(imageUrl: string) {
        this.currentImage = imageUrl;
    }
}
