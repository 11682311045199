import { render, staticRenderFns } from "./FilterArticle.vue?vue&type=template&id=1224dd4d&"
import script from "./FilterArticle.vue?vue&type=script&lang=ts&"
export * from "./FilterArticle.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports