






import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';

@Component
export default class ProductImage extends Vue {
    @Inject({
        default: () => null
    }) readonly registerProductImage!: Function;

    @Prop({ required: true }) thumb: string;
    @Prop({ required: true }) image: string;
    @Prop() ytId: string;

    active = false;

    mounted() {
        this.registerProductImage(this);
    }

    get isVideo(): boolean {
        return (this.ytId && this.ytId.length > 0) || !!this.image.match(/.*\.mp4/);
    }

    get isYTVideo(): boolean {
        return this.ytId && this.ytId.length > 0;
    }
}
