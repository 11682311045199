























import Vue from 'vue';
import { Component, Prop, Provide } from 'vue-property-decorator';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import Icon from './Icon.vue';

@Component({
    components: { Icon, CollapseTransition }
})
export default class Dropdown extends Vue {
    @Provide('register') childRegistration = this.registerItem;
    @Prop() label: string;
    @Prop({ default: 'left' }) alignment: 'left' | 'right';
    @Prop({ default: false, type: Boolean }) contrast: boolean;
    @Prop({ default: false }) form: boolean;
    @Prop({ default: false }) fullWidth: boolean;
    @Prop({ default: true }) shortHeight: boolean;
    @Prop({ default: false, type: Boolean }) showIcon: boolean;
    @Prop({ default: false }) disable: boolean;
    @Prop({ default: '' }) title!: string;

    open = false;
    activeTitle = this.title;
    activeValue = '';
    items = [];

    mounted() {
        document.addEventListener('click', this.onOutsideClick);
    }

    beforeDestroy() {
        document.removeEventListener('click', this.onOutsideClick);
    }

    // open/close dropdown
    openDropdown() {
        if (!this.disable) {
            this.open = !this.open;
        }
    }

    // called by child items
    registerItem(item) {
        item.contrast = this.contrast;
        this.items.push(item);
        item.$on('click', ({ label, value }) => {
            this.activeTitle = this.activeTitle === label ? this.title : label;
            this.activeValue = this.activeValue === value ? '' : value;
            this.$emit('select', { label, value });
            this.open = false;
        });
    }

    onOutsideClick(evt) {
        let targetElement = evt.target;

        do {
            if (targetElement === this.$el) {
                return;
            }
            targetElement = targetElement.parentNode;
        } while (targetElement);
        this.open = false;
    }
}
