

























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Cookies from 'js-cookie';

@Component
export default class Popup extends Vue {
    @Prop({ default: 'popup' }) layout: 'popup' | 'banner';
    @Prop() id: string;
    @Prop({ default: -1 }) autostart: number;

    open = false;
    visible = false;
    step = 0;
    values: string[] = [];

    created(): void {
        this.$root.$on('open-popup', this.onOpen);
        this.initCookie();
    }

    mounted(): void {
        const alreadyShown = !!Cookies.get('popup-shown');
        if (this.autostart > 0 && !alreadyShown) {
            setTimeout(() => {
                this.onOpen(this.id);
            }, this.autostart * 1000);
        }
    }

    onOpen(id: string): void {
        if (id === this.id) {
            this.open = true;
            setTimeout(() => {
                this.visible = true;
            }, 300);
        }
        if (this.layout === 'popup') {
            document.body.classList.add('overflow-hidden');
        }
    }

    next(): void {
        this.step++;
    }

    initCookie(): void {
        if (!Cookies.get('categories')) {
            Cookies.set('categories', 'none');
        }
    }

    updateCookie(): void {
        Cookies.set('categories', this.values.join(', '));
    }

    setCookie(): void {
        Cookies.set('popup-shown', 'true');
    }

    bannerNext(): void {
        this.visible = false;
        setTimeout(() => {
            this.step++;
            this.$nextTick(() => {
                this.visible = true;
            });
        }, 300);
        // in banner mode, the popup opens in second step
        document.body.classList.add('overflow-hidden');
    }

    close(): void {
        this.visible = false;
        setTimeout(() => {
            this.open = false;
            this.step = 0;
        }, 300);
        document.body.classList.remove('overflow-hidden');
        Cookies.set('categories', 'none');
        this.setCookie();
    }

    setValues(values: string[]): void {
        this.values = values;
        this.updateCookie();
    }

    get query(): string {
        if (this.values.length) {
            return `#categories:${this.values.join(',')}`;
        }
        return '';
    }
}
