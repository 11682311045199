import Vue from 'vue';
import Dummy from './components/Dummy.vue';
import Slider from './components/base/Slider.vue';
import Slide from './components/base/Slide.vue';
import MainNavigation from './components/navigation/MainNavigation.vue';
import MainNavItem from './components/navigation/MainNavItem.vue';
import MetaNavItem from './components/navigation/MetaNavItem.vue';
import Dropdown from './components/base/Dropdown.vue';
import DropdownItem from './components/base/DropdownItem.vue';
import FooterNavItem from './components/navigation/FooterNavItem.vue';
import Accordion from './components/base/Accordion.vue';
import AccordionTeaser from './components/AccordionTeaser.vue';
import AccordionTeaserItem from './components/AccordionTeaserItem.vue';
import Overview from './components/overview/Overview.vue';
import OverviewItem from './components/overview/OverviewItem.vue';
import Filter from './components/overview/Filter.vue';
import FilterArticle from './components/overview/FilterArticle.vue';
import Category from './components/overview/Category.vue';
import CategoryArticle from './components/overview/CategoryArticle.vue';
import FormSubmit from './components/form/FormSubmit.vue';
import Tabs from './components/base/Tabs.vue';
import Tab from './components/base/Tab.vue';
import ProductImages from './components/ProductImages.vue';
import ProductImage from './components/ProductImage.vue';
import YtVideo from './components/base/YtVideo.vue';
import MultiStepForm from './components/form/MultiStepForm.vue';
import AutocompleteInput from './components/AutocompleteInput.vue';
import CheckboxGroup from './components/form/CheckboxGroup.vue';
import FormDropdown from './components/form/FormDropdown.vue';
import ServiceForm from './components/form/ServiceForm.vue';
import StoriesOverview from './components/stories/StoriesOverview.vue';
import StoryItem from './components/stories/StoryItem.vue';
import Popup from './components/base/Popup.vue';
import SearchResults from './components/search/SearchResults.vue';
import BackToTop from './components/base/BackToTop.vue';
import Lightbox from './components/Lightbox.vue';
import RadioRevealer from './components/base/RadioRevealer.vue';
import FruechteBoxProductBox from './components/form/FruechteBoxProductBox.vue';
import FruechteBoxProductSelection from './components/form/FruechteBoxProductSelection.vue';
import CheckboxRevealer from './components/base/CheckboxRevealer.vue';
import DateInput from './components/base/DateInput.vue';

// register components
Vue.component('dummy', Dummy);
Vue.component('slct-slider', Slider);
Vue.component('slct-slide', Slide);
Vue.component('main-navigation', MainNavigation);
Vue.component('main-nav-item', MainNavItem);
Vue.component('meta-nav-item', MetaNavItem);
Vue.component('footer-nav-item', FooterNavItem);
Vue.component('accordion', Accordion);
Vue.component('dropdown', Dropdown);
Vue.component('dropdown-item', DropdownItem);
Vue.component('accordion-teaser', AccordionTeaser);
Vue.component('accordion-teaser-item', AccordionTeaserItem);
Vue.component('slct-overview', Overview);
Vue.component('slct-item', OverviewItem);
Vue.component('slct-filter', Filter);
Vue.component('slct-filter-art', FilterArticle);
Vue.component('slct-category', Category);
Vue.component('slct-category-art', CategoryArticle);
Vue.component('form-submit', FormSubmit);
Vue.component('slct-tabs', Tabs);
Vue.component('slct-tab', Tab);
Vue.component('product-images', ProductImages);
Vue.component('product-image', ProductImage);
Vue.component('yt-video', YtVideo);
Vue.component('multi-step-form', MultiStepForm);
Vue.component('autocomplete-input', AutocompleteInput);
Vue.component('checkbox-group', CheckboxGroup);
Vue.component('form-dropdown', FormDropdown);
Vue.component('service-form', ServiceForm);
Vue.component('stories-overview', StoriesOverview);
Vue.component('story-item', StoryItem);
Vue.component('slct-popup', Popup);
Vue.component('search-results', SearchResults);
Vue.component('back-to-top', BackToTop);
Vue.component('lightbox', Lightbox);
Vue.component('radio-revealer', RadioRevealer);
Vue.component('fruechte-box-product-box', FruechteBoxProductBox);
Vue.component('fruechte-box-product-selection', FruechteBoxProductSelection);
Vue.component('checkbox-revealer', CheckboxRevealer);
Vue.component('date-input', DateInput);
